<template>
  <div class="container">
    <el-dialog
      title="信息编辑"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div class="content">
        <el-form
          ref="form"
          :rules="formRules"
          :model="form"
          label-width="170px"
        >
          <el-form-item prop="shop_name" label="店铺名称">
            <el-input disabled v-model="form.shop_name"></el-input>
          </el-form-item>
          <el-form-item label="头像上传">
            <el-upload
              class="avatar-uploader"
              :action="baseUrl + '/merapi/upload/img'"
              :show-file-list="false"
              :headers="{ Authorization: token }"
              name="upload_file"
              :on-success="handleAvatarSuccess"
              :on-error="handleAvatarError"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl || shopInfo.shop_pic"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item prop="contact" label="联系人">
            <el-input v-model="form.contact"></el-input>
          </el-form-item>

          <el-form-item prop="cell_phone" label="手机号">
            <el-input v-model="form.cell_phone"></el-input>
          </el-form-item>
          <el-form-item prop="email" label="邮箱">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="支付宝账号（日结）">
            <el-input disabled v-model="form.account_number"></el-input>
          </el-form-item>
          <el-form-item label="支付宝姓名（日结）">
            <el-input disabled v-model="form.real_name"></el-input>
          </el-form-item>
          <el-form-item>
            <div style="color: red">
              提示：如需更换日结账户信息，请联系客服修改。
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeVisible">取 消</el-button>
        <el-button type="primary" @click="shopInfoSetting">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { BASE_URL2 as baseUrl } from "../request/request.config";
export default {
  name: "information",
  data() {
    // 校验手机号
    const checkPhone = async (rule, value, callback) => {
      const reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9])\d{8}$/;

      const boolean = reg.test(value);

      if (!boolean) {
        callback(new Error("请输入正确的手机号"));
      }
    };
    const checkZfbNo = async (rule, value, callback) => {
      const reg = /^[\u4e00-\u9fa5]{0,}$/;

      const boolean = reg.test(value);

      if (boolean) {
        callback(new Error("请输入正确的支付宝账号"));
      }
    };
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };
    return {
      baseUrl,
      form: {
        // 表单
        shop_name: "",
        real_name: "",
        account_number: "",
        contact: "",
        cell_phone: "",
        email: "",
      },
      imageUrl: "",
      storeInfo: {},
      // 表单校验
      formRules: {
        shop_name: [{ required: true, message: "名称必填" }],
        contact: [{ required: true, message: "联系人必填" }],
        cell_phone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        email: [{ required: true, validator: checkEmail, trigger: "blur" }],
      },
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shopInfo() {
      return JSON.parse(window.localStorage.getItem("shopInfo")) || {};
    },
    token: {
      get() {
        return window.localStorage.getItem("token") || "";
      },
    },
  },
  created() {
    this.getShopInfo();
  },
  methods: {
    handleAvatarError() {
      this.$message.error("服务器繁忙，请稍后再试");
    },
    getShopInfo() {
      // 获取商户信息

      this.$http2
        .post("/merapi/shop/info")
        .then((res) => {
          this.storeInfo = res.data.data;
          this.form.shop_name = res.data.data.shop_name;
          this.form.account_number = res.data.data.account_number;
          this.form.real_name = res.data.data.real_name;
          this.form.contact = res.data.data.contact;
          this.imageUrl = res.data.data.header_img;
          this.form.cell_phone = res.data.data.cell_phone;
          this.form.email = res.data.data.email;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data.path;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    shopInfoSetting() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          var data = this.form;
          let reg = /^[\u4e00-\u9fa5]{0,}$/;
          if (data.account_number && reg.test(data.account_number)) {
            return this.$message.error("请输入正确的支付宝账号");
          }
          data.shop_id = this.shopInfo.shop_id;
          data.shop_pic = this.imageUrl;
          this.$http2
            .post("/merapi/shop/setinfo", data)
            .then((res) => {
              this.$message.success("修改成功");
              var data = this.shopInfo;
              data.shop_name = this.form.shop_name;
              data.account_number = this.form.account_number;
              data.real_name = this.form.real_name;
              data.contacts = this.form.contact;
              data.email = this.form.email;
              data.shop_pic = this.imageUrl;
              window.localStorage.setItem("shopInfo", JSON.stringify(data));
              location.reload();
              this.getShopInfo();
              this.changeVisible();
            })
            .catch((err) => {
              return this.$message.error(err);
            });
        }
      });
    },
    changeVisible() {
      this.$emit("changeDialogVisible", false);
    },
  },
};
</script>

<style  lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>
