<template>
  <div class="container">
    <el-dialog title="修改密码" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="passwordVisible" width="50%">
      <div class="content">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="输入手机号" prop="phone">
            <el-input type="text" v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="输入验证码">
            <div class="flex-box">
              <el-input type="text" v-model="ruleForm.code"></el-input>
              <el-button :disabled="disabled" @click="getCode">{{ timetext }}</el-button>
            </div>
          </el-form-item>
          <el-form-item label="设置新密码" prop="passwd">
            <el-input type="text" v-model="ruleForm.passwd"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirm_passwd">
            <el-input type="text" v-model="ruleForm.confirm_passwd"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeVisible">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.confirm_passwd !== '') {
          this.$refs.ruleForm.validateField('confirm_passwd')
        }
        callback()
      }
    }
    const checkPhone = async (rule, value, callback) => {
      const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9])\d{8}$/

      const boolean = reg.test(value)


      if (!boolean) {
        callback(new Error('请输入正确的手机号'))

      }


    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.passwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        phone: '',
        code: '',
        passwd: '',
        confirm_passwd: ''
      },
      disabled: false,
      timetext: '获取验证码',
      rules: {
        passwd: [{ validator: validatePass, trigger: 'blur' }],
        phone: [{ validator: checkPhone, trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' },],
        confirm_passwd: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },
  props: {
    passwordVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shopInfo: {
      get() {
        return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
      },
      set() {

      }
    }
  },
  methods: {
    getCode() {
      let that = this;
      let phone = this.ruleForm.phone;
      // if (!phone) return this.$message('请输入手机号')
      this.$http2.post('/merapi/sms/send', { phone }).then(res => {

        if (res.data.status == 200) {
          that.disabled = true;

          let n = 240;
          let run = setInterval(function () {
            n--;
            if (n < 0) {
              clearInterval(run);

              that.disabled = false,

                that.timetext = '重新获取'

            } else {

              that.timetext = "剩余 " + n + "s"
            }

          }, 1000);
        } else {
          return this.$message.error(res.data.msg)

        }

      }).catch(err => {
        this.changeVisible()
        return this.$message.error(err)
      })
    },
    changeVisible() {
      this.$emit('changePasswordVisible', false)
    },
    submitForm(formName) { // 提交申请
      this.$refs[formName].validate(valid => {
        if (valid) {
          var data = this.ruleForm
          this.$http2.post('/merapi/shop/set_password', data).then(res => {
            console.log(res)
            if (res.data.code == 0 || res.data.code == 200) {
              this.$message.success('密码修改成功，请重新登录')
              this.changeVisible()
              this.$router.push('/login')
            } else {
              this.$message.error(res.data.msg)
            }

          }).catch(err => {
            this.changeVisible()
            return this.$message.error(err)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang = "less">
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-button {
    margin-left: 20px;
  }
}
</style>
